import React from "react";
import { Nav, Navbar } from "react-bootstrap";

class Banner extends React.Component {
    render() {
        return (
            <Navbar bg="dark" variant="dark">
                <Navbar.Brand href="#portfolio">Huntington Jasperson</Navbar.Brand>
                <Nav className="mr-auto">
                    <Nav.Link href="#home">about</Nav.Link>
                    <Nav.Link href="#portfolio">portfolio</Nav.Link>
                    <Nav.Link href="#contact">contact</Nav.Link>
                </Nav>
            </Navbar>
        )
    }
}

export default Banner;
import React, { useState } from "react";
import Banner from './components/Banner';
import { Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import portfolio from './Huntington Jasperson - Engineering Design Portfolio.pdf';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }


  return (
    <div className="App">
      <Alert variant='primary'>
        This site is current under construction
      </Alert>
      <Banner />
      <Document
        file={portfolio}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {Array.from(
          new Array(numPages),
          (el, index) => (
            <Page
              justifyContent='center'
              scale={1.5}
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          )
        )}
      </Document>
      <p>Page {pageNumber} of {numPages}</p>
    </div>
  );

}

export default App;
